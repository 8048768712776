<template>
    <vs-row vs-justify="center">
        <vs-col type="flex" vs-justify="center" vs-align="center" vs-lg="12" vs-xs="12">
            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="3">
                        <h3>Extras</h3>
                    </vs-col>
                    <vs-col vs-lg="3">
                        <vs-select autocomplete class="selectExample" label="Categoria" v-model="categoria">
                            <vs-select-item :value="0" text="Todas" />
                            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categorias" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="3">
                        <vs-select autocomplete label="Edicion" v-model="edicion">
                            <vs-select-item :value="0" text="Todas" />
                            <vs-select-item :key="index" :value="item.edition" :text="item.edition" v-for="(item,index) in years" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-offset="2" vs-lg="1" vs-align="flex-end">
                        <vs-button @click="nuevo" color="success" type="relief">Agregar Nuevo</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12">
                        
                            <div slot="header">
                                <div class="d-flex align-items-center">
                                <div>
                                    <h5 class="card-title">Participantes</h5>
                                    <h6 class="card-subtitle mb-0">Todos los participantes, ya sean de seccion especial o de concurso</h6>
                                </div>
                                </div>
                            </div>
                            <VityTable 
                                v-if="url"
                                :columns="columns"
                                :url="url+'extras'"
                                :params="{
                                    admin: 1,
                                    category: (categoria ? categoria : null),
                                    edition: (edicion ? edicion : null)
                                }"
                                :orderBy="{
                                    column: 0,
                                    type: 1
                                }"
                            />
                    
                    </vs-col>
                </vs-row>
            </vs-card>

            <vs-card v-if="extra">
                <h4 class="card-title d-flex">
                    Extra {{extra.title}} ({{extra.edition}})
                </h4>
                <p class="card-subtitle">
                    Aqui podras cambiar toda la informacion del extra selecionado
                </p>
                
                <vs-row vs-justify="center">
                    <vs-col vs-lg="2" vs-xs="2">
                        <label for="" class="mr-2 mb-0">Activo</label>
                        <vs-switch color="success" v-model="extra.active"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input maxlength="255" label="Titulo" class="inputx" placeholder="Titulo" v-model="extra.title"/>
                    </vs-col>
                    <vs-col vs-lg="12" vs-xs="12">
                        <br>
                        <label>Descripcion</label>
                        <vs-textarea rows="3" :counter-danger.sync="descripcionDanger" v-model="extra.description" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <label>Imagen</label>
                        <input type="file" @change="cambiarFoto" />
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12" style="max-height: 198px">
                        <img style="display: block;min-width: 100%;width: 100%;min-height: 100%;height: 100%;" v-show="extra.img" :src="extra.img"/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-select autocomplete class="selectExample" label="Categoria" v-model="extra.category_id">
                            <vs-select-item :key="index" :value="item.id" :text="item.name" v-for="(item,index) in categorias" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-select autocomplete class="selectExample" label="Edicion" v-model="extra.edition">
                            <vs-select-item :key="index" :value="item.edition" :text="item.edition" v-for="(item,index) in years" />
                        </vs-select>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Creado el" class="inputx" placeholder="Creado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', extra.created_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <vs-input label="Actualizado el" class="inputx" placeholder="Actualizado el" :value="$parent.$parent.formatDate('d/m/Y H:i:s', extra.updated_at, true)" disabled/>
                    </vs-col>
                    <vs-col vs-lg="4" vs-xs="12">
                        <label>Activado el</label><br>
                        <v-date-picker v-model="extra.actived_at" mode="dateTime" is24hr>
                            <template v-slot="{ inputValue, inputEvents }">
                                <input
                                class="px-2 py-1 border focus:outline-none focus:border-blue-300"
                                :value="inputValue"
                                v-on="inputEvents"
                                />
                            </template>
                        </v-date-picker>
                    </vs-col>
                </vs-row>
                <vs-row>
                    <vs-col vs-lg="12" vs-xs="12">
                        <vs-button @click="save" color="success" type="relief">Guardar</vs-button>
                    </vs-col>
                </vs-row>
            </vs-card>
            <vs-card v-if="extra">
                <h4 class="card-title d-flex">
                    Contenido
                </h4>
                <vs-row v-if="![3,4].includes(extra.category_id)">
                    <vs-col vs-lg="12" vs-xs="12">
                        <input type="file" @change="cambiarFile" />
                    </vs-col>
                </vs-row>
                <vs-row vs-justify="center">
                    <vs-col vs-lg="12" vs-xs="12">
                        <video controls v-show="extra.file" style="max-width: 100%">
                            <source :src="extra.file"/>
                        </video>
                    </vs-col>
                </vs-row>
                <vs-row v-if="extra.file">
                    <a :href="extra.file" download>Descargar</a>
                </vs-row>
            </vs-card>
        </vs-col>
    </vs-row>
</template>

<script>
import axios from 'axios'
import VityTable from "@/components/VityTable.vue";

export default {
    name: 'Trabajos',
    components: {
        VityTable
    },
    data: function(){
        return{
            columns:[
                {
                    name: 'ID',
                    dbName: 'id',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Titulo',
                    dbName: 'title',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Categoria',
                    dbName: 'categoryName',
                    orderable: true,
                    searchable: false
                },
                {
                    name: 'Edicion',
                    dbName: 'edition',
                    orderable: true,
                    searchable: true
                },
                {
                    name: 'Accion',
                    dbName: '',
                    orderable: false,
                    searchable: false,
                    template: '<a href="/extras/TPLData">EDITAR</a>',
                    reference: 'id'
                },
            ],
            url: process.env.VUE_APP_URL,
            extra: null,
            descripcionDanger: false,
            required: [
                {
                    name: 'title',
                    viewName: 'Titulo'
                },
                {
                    name: 'category_id',
                    viewName: 'Categoria'
                },
                {
                    name: 'edition',
                    viewName: 'Edicion'
                },
            ],
            nuevaImagen: null,
            nuevoFile: null,
            categorias: [],
            years: [],
            categoria: 0,
            edicion: 0,
        } 
    },
    methods:{
        getObra: function(id){
            if(!id)
                this.participant = null;
            else{
                axios
                    .get(process.env.VUE_APP_URL+'extras/'+id, {
                        params:{
                            admin: 1
                        }
                    })
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.extra = rs.data;
                            this.extra.active = this.extra.active ? true : false;
                        }
                        else{
                            this.extra = null;
                            this.$parent.$parent.errors = ['Trabajo no encontrado'];
                        }
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Fallo al conectar'];
                    });
            }
        },
        getCategorias: function(){
            axios
                .get(process.env.VUE_APP_URL+'categories', {
                    params: {
                        all: 1,
                        extra: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK')
                        this.categorias = rs.data;
                });
        },
        save: function(){
            if(this.$parent.$parent.checkParams(this.required, this.extra))
                return;

            var params = new FormData();
            params.append('active', this.extra.active ? 1 : 0);
            params.append('title', this.extra.title);
            params.append('category_id', this.extra.category_id);
            params.append('edition', this.extra.edition);
            if(this.extra.actived_at)
                params.append('actived_at', this.$parent.$parent.formatDate('Y-m-d H:i:s', this.extra.actived_at));
            if(this.extra.description)
                params.append('description', this.extra.description);
            if(this.nuevaImagen)
                params.append('img', this.nuevaImagen);
            if(this.nuevoFile)
                params.append('file', this.nuevoFile);

            this.$vs.loading();
            if(this.extra.id){
                axios
                    .post(process.env.VUE_APP_URL+'extras/'+this.extra.id, params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK'){
                            this.getObra(this.$route.params.id);
                            //this.$router.push('participantes/'+rs.data);
                            this.$parent.$parent.aciertos = ['Guardado correctamente'];
                        }
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    })
                    .finally(() => this.$vs.loading.close());
            }
            else{
                axios
                    .post(process.env.VUE_APP_URL+'extras', params)
                    .then(response => {
                        let rs = response.data;
                        if(rs.state == 'OK')
                            this.$router.push('/extras/'+rs.data);
                        else
                            this.$parent.$parent.errors = [rs.msg];
                    })
                    .catch(error => {
                        this.$parent.$parent.errors = ['Hubo un problema al conectar'];
                    })
                    .finally(() => this.$vs.loading.close());
            }
        },
        nuevo: function(){
            this.$router.push('/extras/new');
        },
        cambiarFoto: function(data){
            this.nuevaImagen = data.target.files[0];
        },
        cambiarFile: function(data){
            this.nuevoFile = data.target.files[0];
        },
        getEdiciones: function(){
            axios
                .get(process.env.VUE_APP_URL+'categories', {
                    params:{
                        edition: 2099,
                        old: 1
                    }
                })
                .then(response => {
                    let rs = response.data;
                    if(rs.state == 'OK'){
                        this.years = rs.data;
                        this.years.unshift({
                            edition: parseInt(rs.data[0].edition) + 1
                        });
                    }
                    else
                        console.log(rs.msg);
                });
        }
    },
    created: function(){
        if(this.$route.params.id == 'new'){
            this.extra = {};
        }
        else{
            this.getObra(this.$route.params.id);
        }
        this.getCategorias();
        this.getEdiciones();
	},
	beforeRouteUpdate (to, from, next) {
        if(to.params.id == 'new'){
            this.extra = {};
        }
        else{
            this.getObra(to.params.id);
        }
        this.getCategorias();
        this.getEdiciones();
		next();
	}
}
</script>